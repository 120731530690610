<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div v-if="showServe == true">
                <div style="
            cursor: pointer;
            font-size: 18px;
            padding-bottom: 15px;
            color: #2ea7e0;
          " @click="showServe = false">
                    返回
                </div>
                <div class="now">企胖胖平台服务协议、隐私权政策、法律说明如下</div>
                <div v-html="textContent"></div>
            </div>

            <div class="propsBox" v-show="showServe == false">
                <div class="now">你当前正在</div>
                <el-steps :active="active" align-center>
                    <el-step title="信息获取"></el-step>
                    <el-step title="接单"></el-step>
                    <el-step title="中标"></el-step>
                    <el-step title="确认订单"></el-step>
                    <el-step title="服务方工作"></el-step>
                    <el-step title="验收成果"></el-step>
                    <el-step title="交易完成"></el-step>
                </el-steps>
                <!-- 获取信息阶段 -->
                <div v-if="active == 0 && !represent">
                    <div class="state">
                        <font><img src="../../assets/images/serve/gth.png" alt="" /> 正在
                            <span>接单</span>
                        </font>
                        <font style="font-size: 14px; color: #666; float: right"><span style="color: red;font-weight: bold;">本月免费查看需求次数</span> {{ info.surplusFreeBid }}</font>
                    </div>
                    <!-- 信息获取 -->
                    <div v-show="obtain == 0">
                        <div class="look">
                            <div>*需要查看</div>
                            <div>
                                {{ infoData.classifyId }} |
                                {{ infoData.name }}
                            </div>
                        </div>
                        <el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="form" @submit.native.prevent>
                            <el-form-item label="*购买预算">
                                <div class="code">
                                    <input style="width: 160px" placeholder="" v-model=" allMoney" readonly type="number" />
                                    <span style="margin: 0 30px 0 10px">元</span>
                                    <!-- <el-input-number style="margin:0 10px" v-model="form1.num" @change="handleChange" :min="1" :max="infoData.bidSurplusNum" label="描述文字"></el-input-number> -->
                                    (库存{{ infoData.bidSurplusNum }}件)
                                </div>
                            </el-form-item>
                            <div class="blue">
                                支付金额后会将商家的联系方式发送至该账户并对需求进行拍价，平台将监督与保障双方的权利和利益
                            </div>
                            <el-form-item label="*联系电话">
                                <div class="code">
                                    <input style="width: 232px" placeholder="13812345678（系统默认）" v-model="info.accountPhone" readonly type="number" />
                                </div>
                            </el-form-item>
                            <el-form-item label="*验证码">
                                <div class="code">
                                    <div class="code">
                                        <input style="width: 458px" v-model="form1.smsCode" type="text" />
                                        <button @click="getCode()" v-show="!countDownShow">
                                            发送验证码
                                        </button>
                                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
                                            {{ countDown }}
                                        </button>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="图形验证码:">
                                <div class="code">
                                    <input style="width: 364px" v-model="smaCode" type="text" />
                                </div>
                                <!-- <el-input style="width: 364px;height:50px" v-model="form.smaCode"></el-input> -->
                                <div class="verification">
                                    <Code ref="ref_validateCode" @change="changeCode" />
                                    <div class="text" @click="changeImg">
                                        看不清？<span>换张图</span>
                                    </div>
                                </div>
                            </el-form-item>

                            <div class="privacy">
                                为保护你的隐私权益，已对商家隐藏你的真实手机号
                            </div>
                            <div class="checked">
                                <el-checkbox v-model="checked"></el-checkbox>
                                <div style="margin-left: 10px">
                                    已阅读并同意<span @click="showServe = true" style="cursor: pointer">企胖胖平台服务协议、隐私权政策、法律说明</span>
                                </div>
                            </div>
                            <div class="foot-btn">
                                <el-button class="orders" @click="compare">选择付款方式</el-button>
                            </div>
                        </el-form>
                    </div>
                    <div v-show="obtain == 1">
                        <div class="hint">*信息查看金额</div>
                        <div class="ysmoney" v-if="info.surplusFreeBid == 0">
                            {{ allMoney }}
                        </div>
                        <div class="ysmoney" v-if="info.surplusFreeBid > 0">0</div>
                        <div class="hint">*支付方式</div>
                        <div class="payment">
                            <el-radio v-model="form1.payType" :label="0" class="raido">
                                <div class="raido-info">
                                    <div>
                                        <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                        我的钱包
                                        <span>余额：{{ info.balance }}元</span>
                                    </div>
                                </div>
                            </el-radio>
                            <div>平台</div>
                        </div>
                        <!-- <div class="payment">
            <el-radio v-model="form1.payType" :label="1" class="raido">
              <div class="raido-info">
                <div>
                  <img
                    style="width: 22px; height: 22px"
                    src="../../assets/images/serve/zfb.png"
                    alt=""
                  />
                  支付宝
                </div>
              </div>
            </el-radio>
          </div> -->
                        <!-- <div class="payment">
            <el-radio v-model="form1.payType" :label="2" class="raido">
              <div class="raido-info">
                <div>
                  <img
                    style="width: 21px; height: 18px"
                    src="../../assets/images/serve/wx.png"
                    alt=""
                  />
                  微信
                </div>
              </div>
            </el-radio>
          </div> -->
                        <!-- <div class="hint">*预首款定金</div>
                    <div class="deposit">
                        <div class="price">
                            <span>7900.00</span>
                            <div>（已支付意向金100.00元）</div>
                        </div>
                        <div>注：若支付全款后意向金有所剩余，平台将在服务方收款后将剩余金额退回 <span>【我的钱包】</span></div>
                    </div> -->
                        <div class="hint">*支付密码</div>
                        <div class="paypass">
                            <input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num" :id="'captcha' + index" @input="inputFinash(index)" @focus="adjust(index)" @keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" />
                        </div>
                        <div class="privacy">你的隐私正在保护中...</div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="payOne">付款</el-button>
                        </div>
                    </div>
                </div>
                <!-- 接单 -->
                <div v-if="active == 1 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        正在 <span>接单</span>
                        <!-- <div style="font-size: 14px; margin-left: 60px">
            提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看
            <span style="color: #2ea7e0">【我的接单】</span>
          </div> -->
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 接受 -->
                    <div class="accept">
                        <span>*我接受</span>
                        <el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" v-model="msg"></el-input>
                    </div>
                    <!-- 等待中 -->
                    <!-- <div class="accepting">
                    <div class="cell">
                        <div>中标服务方</div>深圳市AABB公司
                    </div>
                    <div class="cell">
                        <div>我接受</div>知识产权|xxx公司计算机著作权申请
                    </div>
                    <div class="cell">
                        <div>联系方式</div>13545678（号码保护中）
                    </div>
                </div> -->

                    <!-- 底部按钮 -->
                    <div class="foot-btn">
                        <el-button class="orders" @click="complete">接单</el-button>
                    </div>
                </div>
                <!-- 中标 -->
                <div v-if="active == 2 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        <span>待中标</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务方</div>
                            {{ info.accountKey }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ msg || "待填写" }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                    </div>
                    <!-- 未中标 -->
                    <div class="no-win" v-show="surrer == 2">
                        <img src="../../assets/images/serve/close.png" alt="" />
                        很遗憾!未中标,可以到 <span>服务中心</span> 逛逛其他需求
                    </div>
                    <!-- 超时 -->
                    <div class="time-out" v-show="surrer == 3">
                        <img src="../../assets/images/serve/sjxjg.png" alt="" />
                        此单已作废,此订单已付款的款项不退款
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="wait" style="color: #e40012" v-show="surrer == 1">
                            <div>恭喜你被选为中标方</div>
                            请在此流程的三天内进行确认，以方便进行后续工作安排，若三天内未确认此流程，则视为服务方放弃拍价
                        </div>
                        <div class="foot-btn" v-show="surrer == 1">
                            <el-button class="orders" @click="winBid">确认</el-button>
                        </div>
                        <div class="wait" v-show="surrer == 0">
                            请耐心等待结果，此流程有更新时将发短信通知
                        </div>
                        <div class="foot-btn" v-show="surrer == 0">
                            <el-button class="orders wait-btn">确认</el-button>
                            <div class="give-up" @click="giveUp">放弃接单</div>
                        </div>
                        <!-- <div class="foot-btn">
                        <el-button class="orders wait-btn">确认</el-button>
                        <div class="give-up">放弃接单</div>
                    </div> -->
                    </div>
                </div>
                <!-- 申请付款 -->
                <div v-if="active == 3 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        已中标,<span>待完善信息</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务方</div>
                            {{ info.accountKey }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>待服务方填写</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>待服务方填写</span>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">*上传合同附件</div>
                        <el-upload :limit="1" :action="action" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="l-hint">
                        <div>重要提示:</div>
                        买家对此处上传合同协议不认可时可以选择联系人入驻商户客服人员更换其他第三方备案的电子合同协议进行后补签署，也可以后补签署纸质合同协议，如需邮寄合同协议由双方自行协商约定并支付相应的邮寄费用。
                    </div>
                    <el-form style="margin-top: 40px" label-position="left" label-width="150px" :model="form2" @submit.native.prevent>
                        <el-form-item label="*合同价格">
                            <div class="code">
                                <input style="width: 250px" v-model="form2.winNeedMoney" type="number" />
                                <span style="margin: 0 30px 0 10px">元</span>
                            </div>
                        </el-form-item>
                        <div class="blue">
                            请对需求进行拍价，平台将建都与保障双发的权利和协议
                        </div>
                        <el-form-item label="*合需求方预付首款">
                            <div class="code">
                                <input style="width: 195px" @input="winFirst" v-model.number="form2.winFirstProportion" type="number" />%
                                <!-- <span style="margin: 0 30px 0 10px">￥{{ infoData.sendMoneyBudget }}</span> -->
                                <span style="margin-left: 30px">比例为100%时尾款为0元</span>
                            </div>
                        </el-form-item>
                    </el-form>
                    <!-- 底部按钮 -->
                    <div>
                        <!-- <div class="wait"> 请在此流程的三天内进行确认，以方便进行后续工作安排，若三天内未确认次流程，则视为服务方放弃拍价</div> -->
                        <div class="foot-btn">
                            <el-button class="orders" @click="contract">确认</el-button>
                        </div>
                        <!-- <div class="foot-btn">
                        <el-button class="orders" >申请付款</el-button>
                        <div class="give-up">申诉</div>
                    </div>
                    <div class="hui">点击【申请付款】后待需求方将首款发至平台，可进行下一步工作。</div> -->
                    </div>
                </div>
                <!-- 申请付款 -->
                <div v-if="active == 4 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        已中标,<span>待完善信息</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务方</div>
                            {{ info.accountKey }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/gzz.png" alt="" />
                        需求方正在付款路上,付款后将短信通知你进行工作
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <!-- <div class="foot-btn">
                        <el-button class="orders wait-btn" >等待服务方工作</el-button>
                    </div> -->
                        <div class="foot-btn">
                            <!-- <el-button class="orders wait-btn">等待服务方上传验收文件</el-button> -->
                            <div class="give-up" @click="appealOpen">申诉</div>
                        </div>
                        <!-- <div class="hui">此流程7个工作日后启动跳转至下个流程</div> -->
                    </div>
                </div>
                <!-- 验收成果 -->
                <div v-if="active == 5 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        <span>待支付首款</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>中标服务方</div>
                            {{ infoData.winUserName }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">*上传文件</div>
                        <el-upload :limit="1" :action="action" :on-success="beforeLoadF" list-type="picture-card" :on-remove="removeImgF">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <!-- <div class="wait"> 请在此流程的三天内进行确认，以方便进行后续工作安排，若三天内未确认次流程，则视为服务方放弃拍价</div>
                    <div class="foot-btn">
                        <el-button class="orders" @click="pay">确认</el-button>
                    </div> -->
                        <div class="foot-btn">
                            <el-button class="orders" @click="flieUloap">确认上传</el-button>
                            <div class="give-up" @click="appealOpen">申诉</div>
                        </div>
                        <!-- <div class="foot-btn">
                        <div class="hui">需求方正在付款的路上...</div>
                    </div> -->
                    </div>
                </div>
                <!-- 交易中 -->
                <div v-if="active == 6 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        正在 <span>交易中</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>中标服务方</div>
                            {{ infoData.winUserName }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>

                    <div class="privacy">你的隐私正在保护中...</div>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders wait-btn">等待需求方验收确认与支付尾款</el-button>
                        </div>
                    </div>
                </div>
                <!-- 交易中 -->
                <div v-if="active == 7 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        交易完成<span>确认付款信息</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>中标服务方</div>
                            {{ infoData.winUserName }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/qr.png" alt="" />
                        交易已完成！现在可以确认收款方式
                    </div>
                    <div class="hint">*收款金额</div>
                    <div class="ysmoney">{{ infoData.winNeedMoney }}</div>
                    <div class="hint">*支付方式</div>
                    <div class="payment">
                        <el-radio v-model="form1.payType" :label="0" class="raido">
                            <div class="raido-info">
                                <div>
                                    <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                    我的钱包
                                    <span>余额：{{ info.balance }}元</span>
                                </div>
                            </div>
                        </el-radio>
                        <div>平台</div>
                    </div>
                    <div class="privacy">你的隐私正在保护中...</div>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="payEnd">确认收款信息</el-button>
                        </div>
                    </div>
                </div>
                <!-- 交易完成 -->
                <div v-if="active == 8 && !represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        交易 <span>完成</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>中标服务方</div>
                            {{ infoData.winUserName }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <!-- <el-button class="orders" @click="pay">确认付款信息</el-button> -->
                            <div class="hui">资金已到平台钱包请仔细核对查看</div>
                        </div>
                    </div>
                </div>
                <!-- 申述 -->
                <div v-if="represent">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        正在 <span>申诉</span>
                        <div style="font-size: 14px; margin-left: 60px">
                            <!-- 提示：此需求你有多个接单，已默认为你显示第一个接单，其他订单可查看 <span style="color:#2EA7E0">【我的接单】</span> -->
                        </div>
                    </div>
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ infoData.orderId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求类别</div>
                            <span>{{ infoData.classifyId }}</span>
                        </div>
                        <div class="cell">
                            <div>需求名称</div>
                            <span>{{ infoData.name }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ infoData.sendMsgBase }}</span>
                        </div>
                        <div class="cell">
                            <div>预算金额</div>
                            <span>￥{{ infoData.sendMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>预付意向金</div>
                            <span>￥{{ infoData.sendMoneyIntention }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ infoData.sendPhone }}</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>中标服务方</div>
                            {{ info.accountKey }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ infoData.winMsgOther }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            {{ info.accountPhone }}
                        </div>
                        <div class="cell">
                            <div>合同价格</div>
                            <span>{{ infoData.winNeedMoney || "待服务方填写" }}</span>
                        </div>
                        <div class="cell">
                            <div>首款比例</div>
                            <span>{{
                infoData.winFirstProportion
                  ? infoData.winFirstProportion + "%"
                  : "待服务方填写"
              }}</span>
                        </div>
                    </div>
                    <div v-if="repreCur == 0">
                        <el-form style="margin-top: 40px" label-position="left" label-width="80px" :model="form" @submit.native.prevent>
                            <el-form-item label="*申诉环节">
                                <div class="code">
                                    <el-select v-model="form.state" placeholder="服务环节">
                                        <el-option v-for="item in linkList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                            <el-form-item label="*申诉原因">
                                <!-- <div class="code"> -->
                                <el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" placeholder="50字以内" v-model="form.msg"></el-input>
                                <!-- </div> -->
                            </el-form-item>
                        </el-form>
                        <!-- 底部按钮 -->
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders" @click="appeal">确定转入申诉</el-button>
                            </div>
                            <div class="hui">
                                申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="orderInformation" style="border: none">
                            <div class="cell">
                                <div>申诉人</div>
                                <span>{{ infoData.errorUserName }}</span>
                            </div>
                            <div class="cell">
                                <div>申诉环节</div>
                                <span>{{ active == 4 ? "服务方工作" : "验收成果" }}</span>
                            </div>
                            <div class="cell">
                                <div>申诉原因</div>
                                <span>{{
                  infoData.errorMsg == "" ? this.form.msg : infoData.errorMsg
                }}</span>
                            </div>
                        </div>
                        <!-- 底部按钮 -->
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders" disabled v-if="!showAppeal">申诉中</el-button>
                                <el-button class="orders" @click="withdraw" v-if="showAppeal">撤回申诉</el-button>
                                <div class="give-up">平台审核员核实中......</div>
                            </div>
                            <div class="hui">
                                申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Code from "../../components/code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean,
        infoData: Object,
    },

    data() {
        return {
            className: "",
            getPhone: false, //获取手机号
            num: 1, //数量
            surrer: 0, //中标等待状态  0正常  1中标  3放弃
            active: 0, //当前进度下标
            checked: false, //勾选隐私条款
            obtain: 0, //获取信息 0 为输入信息 1 支付
            form1: {
                //付款
                orderId: "",
                num: 1,
                payType: 0,
                payPsd: "",
                smsCode: "",
            },
            form2: {
                //填写合同货款信息
                orderId: "",
                contract: "",
                winNeedMoney: "",
                winFirstProportion: "",
            },
            form: {
                //申诉
                state: "",
                msg: "",
                orderId: "",
            },
            form3: {
                //上传文件
                orderId: "",
                fileUrl: "",
            },
            // 申诉的状态
            linkList: [],
            action: this.$util.upload,
            msg: "", //详细描述
            smaCode: "", //验证码
            radio: 0, //zhifu
            activeInput: 0,
            captchas: [
                // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
            ],
            errorState: {
                //页面展示的文字  根据数字
                4: "服务方工作",
                7: "验收成果",
            },
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            checkCode: "", //图形码
            info: "",
            other: [],
            represent: false, // 申述
            repreCur: 0,
            showServe: false,
            textContent: "",
            showAppeal: false,
            allMoney: "",
        };
    },
    watch: {
        // 深度监听active的改变进行申诉的转态
        active: {
            handler(val) {
                console.log(val);

                if (val == 4) {
                    this.linkList = [
                        { id: 4, title: "服务方工作" },
                        // { id: 7, title: '服务方工作' }
                    ];
                } else if (val == 5) {
                    this.linkList = [
                        { id: 4, title: "服务方工作" },
                        { id: 7, title: "验收成果" },
                    ];
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.getClass();
    },
    mounted() {
        this.getText();
        this.getInfo();
        this.BooleanRed();

        //errorState    0 正常   不等于0就证明已经进行申诉中
        if (this.infoData.errorState != 0) {
            this.represent = true;
            this.repreCur = 1;
        }

        //2级判断
        if (this.infoData.state == 3) {
            this.active = 2;
            if (this.infoData.stateType == 1) {
                this.active = 1;
            }
        } else if (this.infoData.state == 4) {
            this.active = 2;
            if (this.infoData.stateType == 3) {
                this.surrer = 1;
            } else if (this.infoData.stateType == 2) {
                this.surrer = 0;
            }
        } else if (this.infoData.state == 5) {
            this.active = 3;
        } else if (this.infoData.state == 6) {
            this.active = 4;
        } else if (this.infoData.state == 7) {
            this.active = 5;
        } else if (this.infoData.state == 8) {
            this.active = 6;
        } else if (this.infoData.state == 9) {
            this.active = 6;
        } else if (this.infoData.state == 10) {
            this.active = 7;
        } else if (this.infoData.state == 11) {
            this.active = 8;
        }

        if (this.infoData.stateType == 4) {
            this.surrer = 2;
            this.active = 2;
        }

        // 未中标
        // if (this.infoData.stateType == 4) {
        //   console.log("aaa")
        //   if(this.infoData.state == 3){
        //      this.surrer = 1;
        //   }else if(this.infoData.state == 2){
        //      this.surrer = 0;
        //   }
        // }
    },

    methods: {
        //消除红点
        BooleanRed() {
            if (this.infoData) {
                if (this.infoData.current == 0 || this.infoData.current == 3) {
                    if (this.infoData.bidRed == true) {
                        this.$util
                            .post("/demand/readeBid", {
                                orderId: this.infoData.orderId,
                            })
                            .then((res) => {
                                console.log(res);
                            });
                    }
                } else {
                    this.$util
                        .post("/demand/readeOrder", {
                            orderId: this.infoData.orderId,
                        })
                        .then((res) => {
                            console.log(res);
                        });
                }
            }
        },
        getText() {
            this.$util
                .post("/sys-param/list", {
                    paramKey: "html_yszc",
                })
                .then((res) => {
                    this.textContent = res.data[0].paramValue.replace(
                        /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
                        (match, capture) => {
                            return match.replace("../../", this.$util.host + "/");
                        }
                    );
                });
        },
        // 获取所有分类
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                if (this.infoData.classifyId) {
                    res.data.forEach((item) => {
                        if (this.infoData.classifyId == item.id) {
                            this.infoData.classifyId = item.name;
                        }
                    });
                }
                this.msg = this.infoData.classifyId + "|" + this.infoData.name;
            });
        },
        // 获取个人信息
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
                if (this.info.id == this.infoData.errorUid) {
                    this.showAppeal = true;
                } else {
                    this.showAppeal = false;
                }
            });

            this.$util
                .post("/sys-param/list", {
                    paramKey: "bid_money",
                })
                .then((res) => {
                    if (this.info.surplusFreeBid > 0) {
                        this.allMoney = 0
                    } else if (this.info.surplusFreeBid == 0) {
                        this.allMoney = this.form1.num * res.data[0].paramValue;
                    }

                });

        },
        handleChange(value) {
            console.log(value);
        },

        handleClose(done) {
            this.$emit("close");
        },
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            this.$util
                .post("sys-sms/sms-send", {
                    phone: this.info.accountPhone,
                    action: "Other",
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.countDownShow = true;
                        this.settime();
                    }
                });
        },
        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false;
                return false;
            } else {
                this.countDownShow = true;
                this.isdisabledFn = true;
                this.countDown = "重新发送" + "(" + this.count + ")";
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        // 选择支付方式
        compare() {
            if (!this.checked) {
                return this.$message.error("请勾选隐私条约进行注册");
            }
            if (this.smaCode.toUpperCase() === this.checkCode) {
                // if (this.active++ > 2) this.active = 0;
                this.obtain = 1;
            } else {
                alert("验证码不正确,请重新输入");
                this.form.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        // 步骤一
        payOne() {
            this.form1.orderId = this.infoData.orderId;
            this.$util.post("/demand/bid", this.form1).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.other = res.other;
                    this.active++;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.form1.payPsd = code;
                }
            }
        },
        // 输入密码2
        adjusto(index) {
            let dom = document.getElementById("captch" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirectiono(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captch" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captch" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinasho(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captch" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                }
            }
        },
        // 完善信息
        complete() {
            this.$util
                .post("/demand/bidMsg", {
                    orderId: this.infoData.orderId,
                    msg: this.msg,
                })
                .then((res) => {
                    if (res.code == 200) {
                        // this.handleClose()
                        this.surrer = 0;
                        console.log(this.surrer);

                        this.active++;
                    }
                });
        },
        // 确认中标
        winBid() {
            this.$util
                .post("/demand/winnerConfirm", {
                    orderId: this.infoData.orderId,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    }
                });
        },
        // 合同比例
        winFirst() {
            if (this.form2.winFirstProportion > 100) {
                this.form2.winFirstProportion = 100;
            } else {
                this.form2.winFirstProportion = parseInt(this.form2.winFirstProportion);
            }
        },
        // 上传合同
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form2.contract = imgs.join(",");
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form2.contract = imgs.join(",");
        },
        // 填写合同货款信息
        contract() {
            this.form2.orderId = this.infoData.orderId
                ? this.infoData.orderId
                : this.infoData.id;
            this.$util.post("/demand/winnerPutMoney", this.form2).then((res) => {
                if (res.code == 200) {
                    this.active++;
                }
            });
        },
        // 上传文件
        beforeLoadF(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form3.fileUrl = imgs.join(",");
        },
        removeImgF(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form3.fileUrl = imgs.join(",");
        },
        // 上传文件
        flieUloap() {
            this.form3.orderId = this.infoData.orderId;
            this.$util.post("/demand/winnerOverWork", this.form3).then((res) => {
                if (res.code == 200) {
                    this.active++;
                }
            });
        },
        // 确认收款
        payEnd() {
            this.$util
                .post("/demand/winnerCollectMoney", {
                    orderId: this.infoData.orderId,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 放弃接单
        giveUp() {
            this.$util
                .post("/center/myOrder/cancelBid", {
                    id: this.other.length > 0 ? this.other[0].id : this.infoData.orderId,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.surrer = 3;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 付款
        pay() {
            if (this.active++ > 6) this.active = 0;
        },
        // 申诉打开
        appealOpen() {
            this.represent = true;
        },
        // 申诉
        appeal() {
            this.form.orderId = this.infoData.orderId
                ? this.infoData.orderId
                : this.infoData.id;
            console.log(this.form);

            this.$util.post("/demand/error/win", this.form).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.repreCur = 1;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 撤回申诉
        withdraw() {
            this.$util
                .post("/demand/error/close", {
                    orderId: this.infoData.orderId
                        ? this.infoData.orderId
                        : this.infoData.id,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.represent = false;
                        this.repreCur = 0;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
    },
    computed: {
        // allMoney() {
        //   var num = "";
        //   this.$util
        //     .post("/sys-param/list", {
        //       paramKey: "bid_money",
        //     })
        //     .then((res) => {
        //       num = res.data[0].paramValue;
        //     });
        //   return this.form1.num * parseInt(num);
        // },
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;

    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        // border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
        span {
            color: #e40012;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    padding: 13px 29px;
    background: #d1d1d1 !important;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.time-out {
    display: flex;
    align-items: center;
    margin: 100px 0;
    color: #ffcc00;
    font-size: 18px;
    justify-content: center;
    img {
        width: 38px;
        height: 32px;
        margin-right: 20px;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}
.orders {
    // margin-top: 90px;
    // width: 247px;
    // height: 50px;
    padding: 13px 100px;
    background: #e40012;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
}
.l-hint {
    div {
        color: #2ea7e0;
        margin-bottom: 10px;
    }
    font-size: 14px;
    color: #222222;
}
.hui {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px;
}
</style>
